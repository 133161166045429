html {
  -webkit-text-size-adjust: 100%;
  -ms-text-size-adjust: 100%;
  font-family: sans-serif;
}

body {
  margin: 0;
}

article, aside, footer, header, nav, section {
  display: block;
}

h1 {
  margin: .67em 0;
  font-size: 2em;
}

figcaption, figure, main {
  display: block;
}

hr {
  box-sizing: content-box;
  height: 0;
  overflow: visible;
}

a {
  -webkit-text-decoration-skip: objects;
  background-color: #0000;
}

a:active, a:hover {
  outline-width: 0;
}

address {
  font-style: normal;
}

b, strong {
  font-weight: inherit;
  font-weight: bolder;
}

code, kbd, pre, samp {
  font-family: SF Mono, Segoe UI Mono, Roboto Mono, Menlo, Courier, monospace;
  font-size: 1em;
}

dfn {
  font-style: italic;
}

small {
  font-size: 80%;
  font-weight: 400;
}

sub, sup {
  vertical-align: baseline;
  font-size: 75%;
  line-height: 0;
  position: relative;
}

sub {
  bottom: -.25em;
}

sup {
  top: -.5em;
}

audio, video {
  display: inline-block;
}

audio:not([controls]) {
  height: 0;
  display: none;
}

img {
  border-style: none;
}

svg:not(:root) {
  overflow: hidden;
}

button, input, optgroup, select, textarea {
  font-family: inherit;
  font-size: inherit;
  line-height: inherit;
  margin: 0;
}

button, input {
  overflow: visible;
}

button, select {
  text-transform: none;
}

[type="reset"], [type="submit"], button, html [type="button"] {
  -webkit-appearance: button;
}

[type="button"]::-moz-focus-inner, [type="reset"]::-moz-focus-inner, [type="submit"]::-moz-focus-inner, button::-moz-focus-inner {
  border-style: none;
  padding: 0;
}

fieldset {
  border: 0;
  margin: 0;
  padding: 0;
}

legend {
  box-sizing: border-box;
  color: inherit;
  max-width: 100%;
  white-space: normal;
  padding: 0;
  display: table;
}

progress {
  vertical-align: baseline;
  display: inline-block;
}

textarea {
  overflow: auto;
}

[type="checkbox"], [type="radio"] {
  box-sizing: border-box;
  padding: 0;
}

[type="number"]::-webkit-inner-spin-button, [type="number"]::-webkit-outer-spin-button {
  height: auto;
}

[type="search"] {
  -webkit-appearance: textfield;
  outline-offset: -2px;
}

[type="search"]::-webkit-search-cancel-button, [type="search"]::-webkit-search-decoration {
  -webkit-appearance: none;
}

::-webkit-file-upload-button {
  -webkit-appearance: button;
  font: inherit;
}

details, menu {
  display: block;
}

summary {
  outline: 0;
  display: list-item;
}

canvas {
  display: inline-block;
}

template, [hidden] {
  display: none;
}

*, :after, :before {
  box-sizing: inherit;
}

html {
  box-sizing: border-box;
  -webkit-tap-highlight-color: transparent;
  font-size: 20px;
  line-height: 1.5;
}

body {
  color: #3b4351;
  text-rendering: optimizelegibility;
  background: #fff;
  font-family: -apple-system, system-ui, BlinkMacSystemFont, Segoe UI, Roboto, Helvetica Neue, sans-serif;
  font-size: .8rem;
  overflow-x: hidden;
}

a {
  color: #5755d9;
  outline: 0;
  text-decoration: none;
}

a:focus {
  box-shadow: 0 0 0 .1rem #5755d933;
}

a.active, a:active, a:focus, a:hover {
  color: #302ecd;
  text-decoration: underline;
}

a:visited {
  color: #807fe2;
}

h1, h2, h3, h4, h5, h6 {
  color: inherit;
  margin-top: 0;
  margin-bottom: .5em;
  font-weight: 500;
  line-height: 1.2;
}

.h1, .h2, .h3, .h4, .h5, .h6 {
  font-weight: 500;
}

.h1, h1 {
  font-size: 2rem;
}

.h2, h2 {
  font-size: 1.6rem;
}

.h3, h3 {
  font-size: 1.4rem;
}

.h4, h4 {
  font-size: 1.2rem;
}

.h5, h5 {
  font-size: 1rem;
}

.h6, h6 {
  font-size: .8rem;
}

p {
  margin: 0 0 1.2rem;
}

a, ins, u {
  -webkit-text-decoration-skip: ink edges;
  text-decoration-skip: ink edges;
}

abbr[title] {
  cursor: help;
  border-bottom: .05rem dotted;
  text-decoration: none;
}

kbd {
  color: #fff;
  background: #303742;
  border-radius: .1rem;
  padding: .1rem .2rem;
  font-size: .7rem;
  line-height: 1.25;
}

mark {
  color: #3b4351;
  background: #ffe9b3;
  border-bottom: .05rem solid #ffd367;
  border-radius: .1rem;
  padding: .05rem .1rem 0;
}

blockquote {
  border-left: .1rem solid #dadee4;
  margin-left: 0;
  padding: .4rem .8rem;
}

blockquote p:last-child {
  margin-bottom: 0;
}

ol, ul {
  margin: .8rem 0 .8rem .8rem;
  padding: 0;
}

ol ol, ol ul, ul ol, ul ul {
  margin: .8rem 0 .8rem .8rem;
}

ol li, ul li {
  margin-top: .4rem;
}

ul {
  list-style: inside;
}

ul ul {
  list-style-type: circle;
}

ol {
  list-style: decimal inside;
}

ol ol {
  list-style-type: lower-alpha;
}

dl dt {
  font-weight: 700;
}

dl dd {
  margin: .4rem 0 .8rem;
}

.lang-zh, .lang-zh-hans, html:lang(zh), html:lang(zh-Hans) {
  font-family: -apple-system, system-ui, BlinkMacSystemFont, Segoe UI, Roboto, PingFang SC, Hiragino Sans GB, Microsoft YaHei, Helvetica Neue, sans-serif;
}

.lang-zh-hant, html:lang(zh-Hant) {
  font-family: -apple-system, system-ui, BlinkMacSystemFont, Segoe UI, Roboto, PingFang TC, Hiragino Sans CNS, Microsoft JhengHei, Helvetica Neue, sans-serif;
}

.lang-ja, html:lang(ja) {
  font-family: -apple-system, system-ui, BlinkMacSystemFont, Segoe UI, Roboto, Hiragino Sans, Hiragino Kaku Gothic Pro, Yu Gothic, YuGothic, Meiryo, Helvetica Neue, sans-serif;
}

.lang-ko, html:lang(ko) {
  font-family: -apple-system, system-ui, BlinkMacSystemFont, Segoe UI, Roboto, Malgun Gothic, Helvetica Neue, sans-serif;
}

.lang-cjk ins, .lang-cjk u, :lang(ja) ins, :lang(ja) u, :lang(zh) ins, :lang(zh) u {
  border-bottom: .05rem solid;
  text-decoration: none;
}

.lang-cjk del + del, .lang-cjk del + s, .lang-cjk ins + ins, .lang-cjk ins + u, .lang-cjk s + del, .lang-cjk s + s, .lang-cjk u + ins, .lang-cjk u + u, :lang(ja) del + del, :lang(ja) del + s, :lang(ja) ins + ins, :lang(ja) ins + u, :lang(ja) s + del, :lang(ja) s + s, :lang(ja) u + ins, :lang(ja) u + u, :lang(zh) del + del, :lang(zh) del + s, :lang(zh) ins + ins, :lang(zh) ins + u, :lang(zh) s + del, :lang(zh) s + s, :lang(zh) u + ins, :lang(zh) u + u {
  margin-left: .125em;
}

.table {
  border-collapse: collapse;
  border-spacing: 0;
  text-align: left;
  width: 100%;
}

.table.table-striped tbody tr:nth-of-type(2n+1) {
  background: #f7f8f9;
}

.table tbody tr.active, .table.table-striped tbody tr.active, .table.table-hover tbody tr:hover {
  background: #eef0f3;
}

.table.table-scroll {
  white-space: nowrap;
  padding-bottom: .75rem;
  display: block;
  overflow-x: auto;
}

.table td, .table th {
  border-bottom: .05rem solid #dadee4;
  padding: .6rem .4rem;
}

.table th {
  border-bottom-width: .1rem;
}

.btn {
  appearance: none;
  color: #5755d9;
  cursor: pointer;
  height: 1.8rem;
  text-align: center;
  -webkit-user-select: none;
  user-select: none;
  vertical-align: middle;
  white-space: nowrap;
  background: #fff;
  border: .05rem solid #5755d9;
  border-radius: .1rem;
  outline: 0;
  padding: .25rem .4rem;
  font-size: .8rem;
  line-height: 1.2rem;
  text-decoration: none;
  transition: background .2s, border .2s, box-shadow .2s, color .2s;
  display: inline-block;
}

.btn:focus {
  box-shadow: 0 0 0 .1rem #5755d933;
}

.btn:focus, .btn:hover {
  background: #f1f1fc;
  border-color: #4b48d6;
  text-decoration: none;
}

.btn.active, .btn:active {
  color: #fff;
  background: #4b48d6;
  border-color: #3634d2;
  text-decoration: none;
}

.btn.active.loading:after, .btn:active.loading:after {
  border-bottom-color: #fff;
  border-left-color: #fff;
}

.btn.disabled, .btn:disabled, .btn[disabled] {
  cursor: default;
  opacity: .5;
  pointer-events: none;
}

.btn.btn-primary {
  color: #fff;
  background: #5755d9;
  border-color: #4b48d6;
}

.btn.btn-primary:focus, .btn.btn-primary:hover {
  color: #fff;
  background: #4240d4;
  border-color: #3634d2;
}

.btn.btn-primary.active, .btn.btn-primary:active {
  color: #fff;
  background: #3a38d2;
  border-color: #302ecd;
}

.btn.btn-primary.loading:after {
  border-bottom-color: #fff;
  border-left-color: #fff;
}

.btn.btn-success {
  color: #fff;
  background: #32b643;
  border-color: #2faa3f;
}

.btn.btn-success:focus {
  box-shadow: 0 0 0 .1rem #32b64333;
}

.btn.btn-success:focus, .btn.btn-success:hover {
  color: #fff;
  background: #30ae40;
  border-color: #2da23c;
}

.btn.btn-success.active, .btn.btn-success:active {
  color: #fff;
  background: #2a9a39;
  border-color: #278e34;
}

.btn.btn-success.loading:after {
  border-bottom-color: #fff;
  border-left-color: #fff;
}

.btn.btn-error {
  color: #fff;
  background: #e85600;
  border-color: #d95000;
}

.btn.btn-error:focus {
  box-shadow: 0 0 0 .1rem #e8560033;
}

.btn.btn-error:focus, .btn.btn-error:hover {
  color: #fff;
  background: #de5200;
  border-color: #cf4d00;
}

.btn.btn-error.active, .btn.btn-error:active {
  color: #fff;
  background: #c44900;
  border-color: #b54300;
}

.btn.btn-error.loading:after {
  border-bottom-color: #fff;
  border-left-color: #fff;
}

.btn.btn-link {
  color: #5755d9;
  background: none;
  border-color: #0000;
}

.btn.btn-link.active, .btn.btn-link:active, .btn.btn-link:focus, .btn.btn-link:hover {
  color: #302ecd;
}

.btn.btn-sm {
  height: 1.4rem;
  padding: .05rem .3rem;
  font-size: .7rem;
}

.btn.btn-lg {
  height: 2rem;
  padding: .35rem .6rem;
  font-size: .9rem;
}

.btn.btn-block {
  width: 100%;
  display: block;
}

.btn.btn-action {
  width: 1.8rem;
  padding-left: 0;
  padding-right: 0;
}

.btn.btn-action.btn-sm {
  width: 1.4rem;
}

.btn.btn-action.btn-lg {
  width: 2rem;
}

.btn.btn-clear {
  color: currentColor;
  height: 1rem;
  opacity: 1;
  width: 1rem;
  background: none;
  border: 0;
  margin-left: .2rem;
  margin-right: -2px;
  padding: .1rem;
  line-height: .8rem;
  text-decoration: none;
}

.btn.btn-clear:focus, .btn.btn-clear:hover {
  opacity: .95;
  background: #f7f8f980;
}

.btn.btn-clear:before {
  content: "✕";
}

.btn-group {
  flex-wrap: wrap;
  display: inline-flex;
}

.btn-group .btn {
  flex: 1 0 auto;
}

.btn-group .btn:first-child:not(:last-child) {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.btn-group .btn:not(:first-child):not(:last-child) {
  border-radius: 0;
  margin-left: -.05rem;
}

.btn-group .btn:last-child:not(:first-child) {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
  margin-left: -.05rem;
}

.btn-group .btn.active, .btn-group .btn:active, .btn-group .btn:focus, .btn-group .btn:hover {
  z-index: 1;
}

.btn-group.btn-group-block {
  display: flex;
}

.btn-group.btn-group-block .btn {
  flex: 1 0 0;
}

.form-group:not(:last-child) {
  margin-bottom: .4rem;
}

fieldset {
  margin-bottom: .8rem;
}

legend {
  margin-bottom: .8rem;
  font-size: .9rem;
  font-weight: 500;
}

.form-label {
  padding: .3rem 0;
  line-height: 1.2rem;
  display: block;
}

.form-label.label-sm {
  padding: .1rem 0;
  font-size: .7rem;
}

.form-label.label-lg {
  padding: .4rem 0;
  font-size: .9rem;
}

.form-input {
  appearance: none;
  color: #3b4351;
  height: 1.8rem;
  max-width: 100%;
  width: 100%;
  background: #fff;
  border: .05rem solid #bcc3ce;
  border-radius: .1rem;
  outline: 0;
  padding: .25rem .4rem;
  font-size: .8rem;
  line-height: 1.2rem;
  transition: background .2s, border .2s, box-shadow .2s, color .2s;
  display: block;
  position: relative;
}

.form-input:focus {
  border-color: #5755d9;
  box-shadow: 0 0 0 .1rem #5755d933;
}

.form-input:-ms-input-placeholder {
  color: #bcc3ce;
}

.form-input::placeholder {
  color: #bcc3ce;
}

.form-input.input-sm {
  height: 1.4rem;
  padding: .05rem .3rem;
  font-size: .7rem;
}

.form-input.input-lg {
  height: 2rem;
  padding: .35rem .6rem;
  font-size: .9rem;
}

.form-input.input-inline {
  vertical-align: middle;
  width: auto;
  display: inline-block;
}

.form-input[type="file"], textarea.form-input, textarea.form-input.input-lg, textarea.form-input.input-sm {
  height: auto;
}

.form-input-hint {
  color: #bcc3ce;
  margin-top: .2rem;
  font-size: .7rem;
}

.has-success .form-input-hint, .is-success + .form-input-hint {
  color: #32b643;
}

.has-error .form-input-hint, .is-error + .form-input-hint {
  color: #e85600;
}

.form-select {
  appearance: none;
  color: inherit;
  height: 1.8rem;
  vertical-align: middle;
  width: 100%;
  background: #fff;
  border: .05rem solid #bcc3ce;
  border-radius: .1rem;
  outline: 0;
  padding: .25rem .4rem;
  font-size: .8rem;
  line-height: 1.2rem;
}

.form-select:focus {
  border-color: #5755d9;
  box-shadow: 0 0 0 .1rem #5755d933;
}

.form-select::-ms-expand {
  display: none;
}

.form-select.select-sm {
  height: 1.4rem;
  padding: .05rem 1.1rem .05rem .3rem;
  font-size: .7rem;
}

.form-select.select-lg {
  height: 2rem;
  padding: .35rem 1.4rem .35rem .6rem;
  font-size: .9rem;
}

.form-select[multiple], .form-select[size] {
  height: auto;
  padding: .25rem .4rem;
}

.form-select[multiple] option, .form-select[size] option {
  padding: .1rem .2rem;
}

.form-select:not([multiple]):not([size]) {
  background: #fff url("data:image/svg+xml;charset=utf8,%3Csvg%20xmlns='http://www.w3.org/2000/svg'%20viewBox='0%200%204%205'%3E%3Cpath%20fill='%23667189'%20d='M2%200L0%202h4zm0%205L0%203h4z'/%3E%3C/svg%3E") right .35rem center / .4rem .5rem no-repeat;
  padding-right: 1.2rem;
}

.has-icon-left, .has-icon-right {
  position: relative;
}

.has-icon-left .form-icon, .has-icon-right .form-icon {
  height: .8rem;
  width: .8rem;
  z-index: 2;
  margin: 0 .25rem;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
}

.has-icon-left .form-icon {
  left: .05rem;
}

.has-icon-left .form-input {
  padding-left: 1.3rem;
}

.has-icon-right .form-icon {
  right: .05rem;
}

.has-icon-right .form-input {
  padding-right: 1.3rem;
}

.form-checkbox, .form-radio, .form-switch {
  min-height: 1.4rem;
  margin: .2rem 0;
  padding: .1rem .4rem .1rem 1.2rem;
  line-height: 1.2rem;
  display: block;
  position: relative;
}

.form-checkbox input, .form-radio input, .form-switch input {
  clip: rect(0, 0, 0, 0);
  height: 1px;
  width: 1px;
  margin: -1px;
  position: absolute;
  overflow: hidden;
}

.form-checkbox input:focus + .form-icon, .form-radio input:focus + .form-icon, .form-switch input:focus + .form-icon {
  border-color: #5755d9;
  box-shadow: 0 0 0 .1rem #5755d933;
}

.form-checkbox input:checked + .form-icon, .form-radio input:checked + .form-icon, .form-switch input:checked + .form-icon {
  background: #5755d9;
  border-color: #5755d9;
}

.form-checkbox .form-icon, .form-radio .form-icon, .form-switch .form-icon {
  cursor: pointer;
  border: .05rem solid #bcc3ce;
  transition: background .2s, border .2s, box-shadow .2s, color .2s;
  display: inline-block;
  position: absolute;
}

.form-checkbox.input-sm, .form-radio.input-sm, .form-switch.input-sm {
  margin: 0;
  font-size: .7rem;
}

.form-checkbox.input-lg, .form-radio.input-lg, .form-switch.input-lg {
  margin: .3rem 0;
  font-size: .9rem;
}

.form-checkbox .form-icon, .form-radio .form-icon {
  height: .8rem;
  width: .8rem;
  background: #fff;
  top: .3rem;
  left: 0;
}

.form-checkbox input:active + .form-icon, .form-radio input:active + .form-icon {
  background: #eef0f3;
}

.form-checkbox .form-icon {
  border-radius: .1rem;
}

.form-checkbox input:checked + .form-icon:before {
  content: "";
  height: 9px;
  width: 6px;
  background-clip: padding-box;
  border: .1rem solid #fff;
  border-width: 0 .1rem .1rem 0;
  margin-top: -6px;
  margin-left: -3px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: rotate(45deg);
}

.form-checkbox input:indeterminate + .form-icon {
  background: #5755d9;
  border-color: #5755d9;
}

.form-checkbox input:indeterminate + .form-icon:before {
  content: "";
  height: 2px;
  width: 10px;
  background: #fff;
  margin-top: -1px;
  margin-left: -5px;
  position: absolute;
  top: 50%;
  left: 50%;
}

.form-radio .form-icon {
  border-radius: 50%;
}

.form-radio input:checked + .form-icon:before {
  content: "";
  height: 6px;
  width: 6px;
  background: #fff;
  border-radius: 50%;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.form-switch {
  padding-left: 2rem;
}

.form-switch .form-icon {
  height: .9rem;
  width: 1.6rem;
  background: #bcc3ce padding-box padding-box;
  border-radius: .45rem;
  top: .25rem;
  left: 0;
}

.form-switch .form-icon:before {
  content: "";
  height: .8rem;
  width: .8rem;
  background: #fff;
  border-radius: 50%;
  transition: background .2s, border .2s, box-shadow .2s, color .2s, left .2s;
  display: block;
  position: absolute;
  top: 0;
  left: 0;
}

.form-switch input:checked + .form-icon:before {
  left: 14px;
}

.form-switch input:active + .form-icon:before {
  background: #f7f8f9;
}

.input-group {
  display: flex;
}

.input-group .input-group-addon {
  white-space: nowrap;
  background: #f7f8f9;
  border: .05rem solid #bcc3ce;
  border-radius: .1rem;
  padding: .25rem .4rem;
  line-height: 1.2rem;
}

.input-group .input-group-addon.addon-sm {
  padding: .05rem .3rem;
  font-size: .7rem;
}

.input-group .input-group-addon.addon-lg {
  padding: .35rem .6rem;
  font-size: .9rem;
}

.input-group .form-input, .input-group .form-select {
  width: 1%;
  flex: auto;
}

.input-group .input-group-btn {
  z-index: 1;
}

.input-group .form-input:first-child:not(:last-child), .input-group .form-select:first-child:not(:last-child), .input-group .input-group-addon:first-child:not(:last-child), .input-group .input-group-btn:first-child:not(:last-child) {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.input-group .form-input:not(:first-child):not(:last-child), .input-group .form-select:not(:first-child):not(:last-child), .input-group .input-group-addon:not(:first-child):not(:last-child), .input-group .input-group-btn:not(:first-child):not(:last-child) {
  border-radius: 0;
  margin-left: -.05rem;
}

.input-group .form-input:last-child:not(:first-child), .input-group .form-select:last-child:not(:first-child), .input-group .input-group-addon:last-child:not(:first-child), .input-group .input-group-btn:last-child:not(:first-child) {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
  margin-left: -.05rem;
}

.input-group .form-input:focus, .input-group .form-select:focus, .input-group .input-group-addon:focus, .input-group .input-group-btn:focus {
  z-index: 2;
}

.input-group .form-select {
  width: auto;
}

.input-group.input-inline {
  display: inline-flex;
}

.form-input.is-success, .form-select.is-success, .has-success .form-input, .has-success .form-select {
  background: #f9fdfa;
  border-color: #32b643;
}

.form-input.is-success:focus, .form-select.is-success:focus, .has-success .form-input:focus, .has-success .form-select:focus {
  box-shadow: 0 0 0 .1rem #32b64333;
}

.form-input.is-error, .form-select.is-error, .has-error .form-input, .has-error .form-select {
  background: #fffaf7;
  border-color: #e85600;
}

.form-input.is-error:focus, .form-select.is-error:focus, .has-error .form-input:focus, .has-error .form-select:focus {
  box-shadow: 0 0 0 .1rem #e8560033;
}

.form-checkbox.is-error .form-icon, .form-radio.is-error .form-icon, .form-switch.is-error .form-icon, .has-error .form-checkbox .form-icon, .has-error .form-radio .form-icon, .has-error .form-switch .form-icon {
  border-color: #e85600;
}

.form-checkbox.is-error input:checked + .form-icon, .form-radio.is-error input:checked + .form-icon, .form-switch.is-error input:checked + .form-icon, .has-error .form-checkbox input:checked + .form-icon, .has-error .form-radio input:checked + .form-icon, .has-error .form-switch input:checked + .form-icon {
  background: #e85600;
  border-color: #e85600;
}

.form-checkbox.is-error input:focus + .form-icon, .form-radio.is-error input:focus + .form-icon, .form-switch.is-error input:focus + .form-icon, .has-error .form-checkbox input:focus + .form-icon, .has-error .form-radio input:focus + .form-icon, .has-error .form-switch input:focus + .form-icon {
  border-color: #e85600;
  box-shadow: 0 0 0 .1rem #e8560033;
}

.form-checkbox.is-error input:indeterminate + .form-icon, .has-error .form-checkbox input:indeterminate + .form-icon {
  background: #e85600;
  border-color: #e85600;
}

.form-input:not(:-ms-input-placeholder):invalid, .form-input:not(:placeholder-shown):invalid {
  border-color: #e85600;
}

.form-input:not(:-ms-input-placeholder):invalid:focus, .form-input:not(:placeholder-shown):invalid:focus {
  background: #fffaf7;
  box-shadow: 0 0 0 .1rem #e8560033;
}

.form-input:not(:-ms-input-placeholder):invalid + .form-input-hint, .form-input:not(:placeholder-shown):invalid + .form-input-hint {
  color: #e85600;
}

.form-input.disabled, .form-input:disabled, .form-select.disabled, .form-select:disabled {
  cursor: not-allowed;
  opacity: .5;
  background-color: #eef0f3;
}

.form-input[readonly] {
  background-color: #f7f8f9;
}

input.disabled + .form-icon, input:disabled + .form-icon {
  cursor: not-allowed;
  opacity: .5;
  background: #eef0f3;
}

.form-switch input.disabled + .form-icon:before, .form-switch input:disabled + .form-icon:before {
  background: #fff;
}

.form-horizontal {
  padding: .4rem 0;
}

.form-horizontal .form-group {
  flex-wrap: wrap;
  display: flex;
}

.form-inline {
  display: inline-block;
}

.label {
  color: #455060;
  background: #eef0f3;
  border-radius: .1rem;
  padding: .1rem .2rem;
  line-height: 1.25;
  display: inline-block;
}

.label.label-rounded {
  border-radius: 5rem;
  padding-left: .4rem;
  padding-right: .4rem;
}

.label.label-primary {
  color: #fff;
  background: #5755d9;
}

.label.label-secondary {
  color: #5755d9;
  background: #f1f1fc;
}

.label.label-success {
  color: #fff;
  background: #32b643;
}

.label.label-warning {
  color: #fff;
  background: #ffb700;
}

.label.label-error {
  color: #fff;
  background: #e85600;
}

code {
  color: #d73e48;
  background: #fcf2f2;
  border-radius: .1rem;
  padding: .1rem .2rem;
  font-size: 85%;
  line-height: 1.25;
}

.code {
  color: #3b4351;
  border-radius: .1rem;
  position: relative;
}

.code:before {
  color: #bcc3ce;
  content: attr(data-lang);
  font-size: .7rem;
  position: absolute;
  top: .1rem;
  right: .4rem;
}

.code code {
  color: inherit;
  width: 100%;
  background: #f7f8f9;
  padding: 1rem;
  line-height: 1.5;
  display: block;
  overflow-x: auto;
}

.img-responsive {
  height: auto;
  max-width: 100%;
  display: block;
}

.img-fit-cover {
  object-fit: cover;
}

.img-fit-contain {
  object-fit: contain;
}

.video-responsive {
  width: 100%;
  padding: 0;
  display: block;
  position: relative;
  overflow: hidden;
}

.video-responsive:before {
  content: "";
  padding-bottom: 56.25%;
  display: block;
}

.video-responsive embed, .video-responsive iframe, .video-responsive object {
  height: 100%;
  width: 100%;
  border: 0;
  position: absolute;
  inset: 0;
}

video.video-responsive {
  height: auto;
  max-width: 100%;
}

video.video-responsive:before {
  content: none;
}

.video-responsive-4-3:before {
  padding-bottom: 75%;
}

.video-responsive-1-1:before {
  padding-bottom: 100%;
}

.figure {
  margin: 0 0 .4rem;
}

.figure .figure-caption {
  color: #66758c;
  margin-top: .4rem;
}

.container {
  width: 100%;
  margin-left: auto;
  margin-right: auto;
  padding-left: .4rem;
  padding-right: .4rem;
}

.container.grid-xl {
  max-width: 1296px;
}

.container.grid-lg {
  max-width: 976px;
}

.container.grid-md {
  max-width: 856px;
}

.container.grid-sm {
  max-width: 616px;
}

.container.grid-xs {
  max-width: 496px;
}

.show-lg, .show-md, .show-sm, .show-xl, .show-xs {
  display: none !important;
}

.cols, .columns {
  flex-wrap: wrap;
  margin-left: -.4rem;
  margin-right: -.4rem;
  display: flex;
}

.cols.col-gapless, .columns.col-gapless {
  margin-left: 0;
  margin-right: 0;
}

.cols.col-gapless > .column, .columns.col-gapless > .column {
  padding-left: 0;
  padding-right: 0;
}

.cols.col-oneline, .columns.col-oneline {
  flex-wrap: nowrap;
  overflow-x: auto;
}

.column, [class~="col-"] {
  max-width: 100%;
  flex: 1;
  padding-left: .4rem;
  padding-right: .4rem;
}

.column.col-1, .column.col-10, .column.col-11, .column.col-12, .column.col-2, .column.col-3, .column.col-4, .column.col-5, .column.col-6, .column.col-7, .column.col-8, .column.col-9, .column.col-auto, [class~="col-"].col-1, [class~="col-"].col-10, [class~="col-"].col-11, [class~="col-"].col-12, [class~="col-"].col-2, [class~="col-"].col-3, [class~="col-"].col-4, [class~="col-"].col-5, [class~="col-"].col-6, [class~="col-"].col-7, [class~="col-"].col-8, [class~="col-"].col-9, [class~="col-"].col-auto {
  flex: none;
}

.col-12 {
  width: 100%;
}

.col-11 {
  width: 91.6667%;
}

.col-10 {
  width: 83.3333%;
}

.col-9 {
  width: 75%;
}

.col-8 {
  width: 66.6667%;
}

.col-7 {
  width: 58.3333%;
}

.col-6 {
  width: 50%;
}

.col-5 {
  width: 41.6667%;
}

.col-4 {
  width: 33.3333%;
}

.col-3 {
  width: 25%;
}

.col-2 {
  width: 16.6667%;
}

.col-1 {
  width: 8.33333%;
}

.col-auto {
  max-width: none;
  width: auto;
  flex: none;
}

.col-mx-auto {
  margin-left: auto;
  margin-right: auto;
}

.col-ml-auto {
  margin-left: auto;
}

.col-mr-auto {
  margin-right: auto;
}

@media (max-width: 1280px) {
  .col-xl-1, .col-xl-10, .col-xl-11, .col-xl-12, .col-xl-2, .col-xl-3, .col-xl-4, .col-xl-5, .col-xl-6, .col-xl-7, .col-xl-8, .col-xl-9, .col-xl-auto {
    flex: none;
  }

  .col-xl-12 {
    width: 100%;
  }

  .col-xl-11 {
    width: 91.6667%;
  }

  .col-xl-10 {
    width: 83.3333%;
  }

  .col-xl-9 {
    width: 75%;
  }

  .col-xl-8 {
    width: 66.6667%;
  }

  .col-xl-7 {
    width: 58.3333%;
  }

  .col-xl-6 {
    width: 50%;
  }

  .col-xl-5 {
    width: 41.6667%;
  }

  .col-xl-4 {
    width: 33.3333%;
  }

  .col-xl-3 {
    width: 25%;
  }

  .col-xl-2 {
    width: 16.6667%;
  }

  .col-xl-1 {
    width: 8.33333%;
  }

  .col-xl-auto {
    width: auto;
  }

  .hide-xl {
    display: none !important;
  }

  .show-xl {
    display: block !important;
  }
}

@media (max-width: 960px) {
  .col-lg-1, .col-lg-10, .col-lg-11, .col-lg-12, .col-lg-2, .col-lg-3, .col-lg-4, .col-lg-5, .col-lg-6, .col-lg-7, .col-lg-8, .col-lg-9, .col-lg-auto {
    flex: none;
  }

  .col-lg-12 {
    width: 100%;
  }

  .col-lg-11 {
    width: 91.6667%;
  }

  .col-lg-10 {
    width: 83.3333%;
  }

  .col-lg-9 {
    width: 75%;
  }

  .col-lg-8 {
    width: 66.6667%;
  }

  .col-lg-7 {
    width: 58.3333%;
  }

  .col-lg-6 {
    width: 50%;
  }

  .col-lg-5 {
    width: 41.6667%;
  }

  .col-lg-4 {
    width: 33.3333%;
  }

  .col-lg-3 {
    width: 25%;
  }

  .col-lg-2 {
    width: 16.6667%;
  }

  .col-lg-1 {
    width: 8.33333%;
  }

  .col-lg-auto {
    width: auto;
  }

  .hide-lg {
    display: none !important;
  }

  .show-lg {
    display: block !important;
  }
}

@media (max-width: 840px) {
  .col-md-1, .col-md-10, .col-md-11, .col-md-12, .col-md-2, .col-md-3, .col-md-4, .col-md-5, .col-md-6, .col-md-7, .col-md-8, .col-md-9, .col-md-auto {
    flex: none;
  }

  .col-md-12 {
    width: 100%;
  }

  .col-md-11 {
    width: 91.6667%;
  }

  .col-md-10 {
    width: 83.3333%;
  }

  .col-md-9 {
    width: 75%;
  }

  .col-md-8 {
    width: 66.6667%;
  }

  .col-md-7 {
    width: 58.3333%;
  }

  .col-md-6 {
    width: 50%;
  }

  .col-md-5 {
    width: 41.6667%;
  }

  .col-md-4 {
    width: 33.3333%;
  }

  .col-md-3 {
    width: 25%;
  }

  .col-md-2 {
    width: 16.6667%;
  }

  .col-md-1 {
    width: 8.33333%;
  }

  .col-md-auto {
    width: auto;
  }

  .hide-md {
    display: none !important;
  }

  .show-md {
    display: block !important;
  }
}

@media (max-width: 600px) {
  .col-sm-1, .col-sm-10, .col-sm-11, .col-sm-12, .col-sm-2, .col-sm-3, .col-sm-4, .col-sm-5, .col-sm-6, .col-sm-7, .col-sm-8, .col-sm-9, .col-sm-auto {
    flex: none;
  }

  .col-sm-12 {
    width: 100%;
  }

  .col-sm-11 {
    width: 91.6667%;
  }

  .col-sm-10 {
    width: 83.3333%;
  }

  .col-sm-9 {
    width: 75%;
  }

  .col-sm-8 {
    width: 66.6667%;
  }

  .col-sm-7 {
    width: 58.3333%;
  }

  .col-sm-6 {
    width: 50%;
  }

  .col-sm-5 {
    width: 41.6667%;
  }

  .col-sm-4 {
    width: 33.3333%;
  }

  .col-sm-3 {
    width: 25%;
  }

  .col-sm-2 {
    width: 16.6667%;
  }

  .col-sm-1 {
    width: 8.33333%;
  }

  .col-sm-auto {
    width: auto;
  }

  .hide-sm {
    display: none !important;
  }

  .show-sm {
    display: block !important;
  }
}

@media (max-width: 480px) {
  .col-xs-1, .col-xs-10, .col-xs-11, .col-xs-12, .col-xs-2, .col-xs-3, .col-xs-4, .col-xs-5, .col-xs-6, .col-xs-7, .col-xs-8, .col-xs-9, .col-xs-auto {
    flex: none;
  }

  .col-xs-12 {
    width: 100%;
  }

  .col-xs-11 {
    width: 91.6667%;
  }

  .col-xs-10 {
    width: 83.3333%;
  }

  .col-xs-9 {
    width: 75%;
  }

  .col-xs-8 {
    width: 66.6667%;
  }

  .col-xs-7 {
    width: 58.3333%;
  }

  .col-xs-6 {
    width: 50%;
  }

  .col-xs-5 {
    width: 41.6667%;
  }

  .col-xs-4 {
    width: 33.3333%;
  }

  .col-xs-3 {
    width: 25%;
  }

  .col-xs-2 {
    width: 16.6667%;
  }

  .col-xs-1 {
    width: 8.33333%;
  }

  .col-xs-auto {
    width: auto;
  }

  .hide-xs {
    display: none !important;
  }

  .show-xs {
    display: block !important;
  }
}

.hero {
  flex-direction: column;
  justify-content: space-between;
  padding-top: 4rem;
  padding-bottom: 4rem;
  display: flex;
}

.hero.hero-sm {
  padding-top: 2rem;
  padding-bottom: 2rem;
}

.hero.hero-lg {
  padding-top: 8rem;
  padding-bottom: 8rem;
}

.hero .hero-body {
  padding: .4rem;
}

.navbar {
  flex-wrap: wrap;
  -ms-flex-align: stretch;
  justify-content: space-between;
  align-items: stretch;
  display: flex;
}

.navbar .navbar-section {
  flex: 1 0 0;
  -ms-flex-align: center;
  align-items: center;
  display: flex;
}

.navbar .navbar-section:not(:first-child):last-child {
  justify-content: flex-end;
}

.navbar .navbar-center {
  flex: none;
  -ms-flex-align: center;
  align-items: center;
  display: flex;
}

.navbar .navbar-brand {
  font-size: .9rem;
  text-decoration: none;
}

.accordion input:checked ~ .accordion-header > .icon:first-child, .accordion[open] .accordion-header > .icon:first-child {
  transform: rotate(90deg);
}

.accordion input:checked ~ .accordion-body, .accordion[open] .accordion-body {
  max-height: 50rem;
}

.accordion .accordion-header {
  padding: .2rem .4rem;
  display: block;
}

.accordion .accordion-header .icon {
  transition: transform .25s;
}

.accordion .accordion-body {
  max-height: 0;
  margin-bottom: .4rem;
  transition: max-height .25s;
  overflow: hidden;
}

summary.accordion-header::-webkit-details-marker {
  display: none;
}

.avatar {
  color: #ffffffd9;
  height: 1.6rem;
  vertical-align: middle;
  width: 1.6rem;
  background: #5755d9;
  border-radius: 50%;
  margin: 0;
  font-size: .8rem;
  font-weight: 300;
  line-height: 1.25;
  display: inline-block;
  position: relative;
}

.avatar.avatar-xs {
  height: .8rem;
  width: .8rem;
  font-size: .4rem;
}

.avatar.avatar-sm {
  height: 1.2rem;
  width: 1.2rem;
  font-size: .6rem;
}

.avatar.avatar-lg {
  height: 2.4rem;
  width: 2.4rem;
  font-size: 1.2rem;
}

.avatar.avatar-xl {
  height: 3.2rem;
  width: 3.2rem;
  font-size: 1.6rem;
}

.avatar img {
  height: 100%;
  width: 100%;
  z-index: 1;
  border-radius: 50%;
  position: relative;
}

.avatar .avatar-icon, .avatar .avatar-presence {
  height: 50%;
  width: 50%;
  z-index: 2;
  background: #fff;
  padding: .1rem;
  position: absolute;
  bottom: 14.64%;
  right: 14.64%;
  transform: translate(50%, 50%);
}

.avatar .avatar-presence {
  height: .5em;
  width: .5em;
  background: #bcc3ce;
  border-radius: 50%;
  box-shadow: 0 0 0 .1rem #fff;
}

.avatar .avatar-presence.online {
  background: #32b643;
}

.avatar .avatar-presence.busy {
  background: #e85600;
}

.avatar .avatar-presence.away {
  background: #ffb700;
}

.avatar[data-initial]:before {
  color: currentColor;
  content: attr(data-initial);
  z-index: 1;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.badge {
  white-space: nowrap;
  position: relative;
}

.badge:not([data-badge]):after, .badge[data-badge]:after {
  color: #fff;
  content: attr(data-badge);
  background: #5755d9 padding-box padding-box;
  border-radius: .5rem;
  display: inline-block;
  transform: translate(-.05rem, -.5rem);
  box-shadow: 0 0 0 .1rem #fff;
}

.badge[data-badge]:after {
  height: .9rem;
  min-width: .9rem;
  text-align: center;
  white-space: nowrap;
  padding: .1rem .2rem;
  font-size: .7rem;
  line-height: 1;
}

.badge:not([data-badge]):after, .badge[data-badge=""]:after {
  height: 6px;
  min-width: 6px;
  width: 6px;
  padding: 0;
}

.badge.btn:after {
  position: absolute;
  top: 0;
  right: 0;
  transform: translate(50%, -50%);
}

.badge.avatar:after {
  z-index: 100;
  position: absolute;
  top: 14.64%;
  right: 14.64%;
  transform: translate(50%, -50%);
}

.breadcrumb {
  margin: .2rem 0;
  padding: .2rem 0;
  list-style: none;
}

.breadcrumb .breadcrumb-item {
  color: #66758c;
  margin: 0;
  padding: .2rem 0;
  display: inline-block;
}

.breadcrumb .breadcrumb-item:not(:last-child) {
  margin-right: .2rem;
}

.breadcrumb .breadcrumb-item:not(:last-child) a {
  color: #66758c;
}

.breadcrumb .breadcrumb-item:not(:first-child):before {
  color: #66758c;
  content: "/";
  padding-right: .4rem;
}

.bar {
  height: .8rem;
  width: 100%;
  background: #eef0f3;
  border-radius: .1rem;
  flex-wrap: nowrap;
  display: flex;
}

.bar.bar-sm {
  height: .2rem;
}

.bar .bar-item {
  color: #fff;
  height: 100%;
  text-align: center;
  width: 0;
  background: #5755d9;
  flex-shrink: 0;
  font-size: .7rem;
  line-height: .8rem;
  display: block;
  position: relative;
}

.bar .bar-item:first-child {
  border-top-left-radius: .1rem;
  border-bottom-left-radius: .1rem;
}

.bar .bar-item:last-child {
  border-top-right-radius: .1rem;
  border-bottom-right-radius: .1rem;
  flex-shrink: 1;
}

.bar-slider {
  height: .1rem;
  margin: .4rem 0;
  position: relative;
}

.bar-slider .bar-item {
  padding: 0;
  position: absolute;
  left: 0;
}

.bar-slider .bar-item:not(:last-child):first-child {
  z-index: 1;
  background: #eef0f3;
}

.bar-slider .bar-slider-btn {
  height: .6rem;
  width: .6rem;
  background: #5755d9;
  border: 0;
  border-radius: 50%;
  padding: 0;
  position: absolute;
  top: 50%;
  right: 0;
  transform: translate(50%, -50%);
}

.bar-slider .bar-slider-btn:active {
  box-shadow: 0 0 0 .1rem #5755d9;
}

.card {
  background: #fff;
  border: .05rem solid #dadee4;
  border-radius: .1rem;
  flex-direction: column;
  display: flex;
}

.card .card-body, .card .card-footer, .card .card-header {
  padding: .8rem .8rem 0;
}

.card .card-body:last-child, .card .card-footer:last-child, .card .card-header:last-child {
  padding-bottom: .8rem;
}

.card .card-body {
  flex: auto;
}

.card .card-image {
  padding-top: .8rem;
}

.card .card-image:first-child {
  padding-top: 0;
}

.card .card-image:first-child img {
  border-top-left-radius: .1rem;
  border-top-right-radius: .1rem;
}

.card .card-image:last-child img {
  border-bottom-left-radius: .1rem;
  border-bottom-right-radius: .1rem;
}

.chip {
  height: 1.2rem;
  max-width: 320px;
  text-overflow: ellipsis;
  vertical-align: middle;
  white-space: nowrap;
  background: #eef0f3;
  border-radius: 5rem;
  -ms-flex-align: center;
  align-items: center;
  margin: .1rem;
  padding: .2rem .4rem;
  font-size: 90%;
  line-height: .8rem;
  text-decoration: none;
  display: inline-flex;
  overflow: hidden;
}

.chip.active {
  color: #fff;
  background: #5755d9;
}

.chip .avatar {
  margin-left: -.4rem;
  margin-right: .2rem;
}

.chip .btn-clear {
  border-radius: 50%;
  transform: scale(.75);
}

.dropdown {
  display: inline-block;
  position: relative;
}

.dropdown .menu {
  max-height: 50vh;
  animation: .15s slide-down;
  display: none;
  position: absolute;
  top: 100%;
  left: 0;
  overflow-y: auto;
}

.dropdown.dropdown-right .menu {
  left: auto;
  right: 0;
}

.dropdown .dropdown-toggle:focus + .menu, .dropdown .menu:hover, .dropdown.active .menu {
  display: block;
}

.dropdown .btn-group .dropdown-toggle:nth-last-child(2) {
  border-top-right-radius: .1rem;
  border-bottom-right-radius: .1rem;
}

.empty {
  color: #66758c;
  text-align: center;
  background: #f7f8f9;
  border-radius: .1rem;
  padding: 3.2rem 1.6rem;
}

.empty .empty-icon {
  margin-bottom: .8rem;
}

.empty .empty-subtitle, .empty .empty-title {
  margin: .4rem auto;
}

.empty .empty-action {
  margin-top: .8rem;
}

.menu {
  min-width: 180px;
  z-index: 300;
  background: #fff;
  border-radius: .1rem;
  margin: 0;
  padding: .4rem;
  list-style: none;
  transform: translateY(.2rem);
  box-shadow: 0 .05rem .2rem #3037424d;
}

.menu.menu-nav {
  box-shadow: none;
  background: none;
}

.menu .menu-item {
  margin-top: 0;
  padding: 0 .4rem;
  text-decoration: none;
  position: relative;
}

.menu .menu-item > a {
  color: inherit;
  border-radius: .1rem;
  margin: 0 -.4rem;
  padding: .2rem .4rem;
  text-decoration: none;
  display: block;
}

.menu .menu-item > a:focus, .menu .menu-item > a:hover, .menu .menu-item > a.active, .menu .menu-item > a:active {
  color: #5755d9;
  background: #f1f1fc;
}

.menu .menu-item .form-checkbox, .menu .menu-item .form-radio, .menu .menu-item .form-switch {
  margin: .1rem 0;
}

.menu .menu-item + .menu-item {
  margin-top: .2rem;
}

.menu .menu-badge {
  height: 100%;
  -ms-flex-align: center;
  align-items: center;
  display: flex;
  position: absolute;
  top: 0;
  right: 0;
}

.menu .menu-badge .label {
  margin-right: .4rem;
}

.modal {
  opacity: 0;
  -ms-flex-align: center;
  justify-content: center;
  align-items: center;
  padding: .4rem;
  display: none;
  position: fixed;
  inset: 0;
  overflow: hidden;
}

.modal.active, .modal:target {
  opacity: 1;
  z-index: 400;
  display: flex;
}

.modal.active .modal-overlay, .modal:target .modal-overlay {
  cursor: default;
  background: #f7f8f9bf;
  display: block;
  position: absolute;
  inset: 0;
}

.modal.active .modal-container, .modal:target .modal-container {
  z-index: 1;
  animation: .2s slide-down;
}

.modal.modal-sm .modal-container {
  max-width: 320px;
  padding: 0 .4rem;
}

.modal.modal-lg .modal-overlay {
  background: #fff;
}

.modal.modal-lg .modal-container {
  box-shadow: none;
  max-width: 960px;
}

.modal-container {
  max-height: 75vh;
  max-width: 640px;
  width: 100%;
  background: #fff;
  border-radius: .1rem;
  flex-direction: column;
  padding: 0 .8rem;
  display: flex;
  box-shadow: 0 .2rem .5rem #3037424d;
}

.modal-container.modal-fullheight {
  max-height: 100vh;
}

.modal-container .modal-header {
  color: #303742;
  padding: .8rem;
}

.modal-container .modal-body {
  padding: .8rem;
  position: relative;
  overflow-y: auto;
}

.modal-container .modal-footer {
  text-align: right;
  padding: .8rem;
}

.nav {
  flex-direction: column;
  margin: .2rem 0;
  list-style: none;
  display: flex;
}

.nav .nav-item a {
  color: #66758c;
  padding: .2rem .4rem;
  text-decoration: none;
}

.nav .nav-item a:focus, .nav .nav-item a:hover {
  color: #5755d9;
}

.nav .nav-item.active > a {
  color: #505c6e;
  font-weight: 700;
}

.nav .nav-item.active > a:focus, .nav .nav-item.active > a:hover {
  color: #5755d9;
}

.nav .nav {
  margin-bottom: .4rem;
  margin-left: .8rem;
}

.pagination {
  margin: .2rem 0;
  padding: .2rem 0;
  list-style: none;
  display: flex;
}

.pagination .page-item {
  margin: .2rem .05rem;
}

.pagination .page-item span {
  padding: .2rem;
  display: inline-block;
}

.pagination .page-item a {
  border-radius: .1rem;
  padding: .2rem .4rem;
  text-decoration: none;
  display: inline-block;
}

.pagination .page-item a:focus, .pagination .page-item a:hover {
  color: #5755d9;
}

.pagination .page-item.disabled a {
  cursor: default;
  opacity: .5;
  pointer-events: none;
}

.pagination .page-item.active a {
  color: #fff;
  background: #5755d9;
}

.pagination .page-item.page-next, .pagination .page-item.page-prev {
  flex: 1 0 50%;
}

.pagination .page-item.page-next {
  text-align: right;
}

.pagination .page-item .page-item-title {
  margin: 0;
}

.pagination .page-item .page-item-subtitle {
  opacity: .5;
  margin: 0;
}

.panel {
  border: .05rem solid #dadee4;
  border-radius: .1rem;
  flex-direction: column;
  display: flex;
}

.panel .panel-footer, .panel .panel-header {
  flex: none;
  padding: .8rem;
}

.panel .panel-nav {
  flex: none;
}

.panel .panel-body {
  flex: auto;
  padding: 0 .8rem;
  overflow-y: auto;
}

.popover {
  display: inline-block;
  position: relative;
}

.popover .popover-container {
  opacity: 0;
  width: 320px;
  z-index: 300;
  padding: .4rem;
  transition: transform .2s;
  position: absolute;
  top: 0;
  left: 50%;
  transform: translate(-50%, -50%)scale(0);
}

.popover :focus + .popover-container, .popover:hover .popover-container {
  opacity: 1;
  display: block;
  transform: translate(-50%, -100%)scale(1);
}

.popover.popover-right .popover-container {
  top: 50%;
  left: 100%;
}

.popover.popover-right :focus + .popover-container, .popover.popover-right:hover .popover-container {
  transform: translate(0, -50%)scale(1);
}

.popover.popover-bottom .popover-container {
  top: 100%;
  left: 50%;
}

.popover.popover-bottom :focus + .popover-container, .popover.popover-bottom:hover .popover-container {
  transform: translate(-50%)scale(1);
}

.popover.popover-left .popover-container {
  top: 50%;
  left: 0;
}

.popover.popover-left :focus + .popover-container, .popover.popover-left:hover .popover-container {
  transform: translate(-100%, -50%)scale(1);
}

.popover .card {
  border: 0;
  box-shadow: 0 .2rem .5rem #3037424d;
}

.step {
  width: 100%;
  flex-wrap: nowrap;
  margin: .2rem 0;
  list-style: none;
  display: flex;
}

.step .step-item {
  min-height: 1rem;
  text-align: center;
  flex: 1 1 0;
  margin-top: 0;
  position: relative;
}

.step .step-item:not(:first-child):before {
  content: "";
  height: 2px;
  width: 100%;
  background: #5755d9;
  position: absolute;
  top: 9px;
  left: -50%;
}

.step .step-item a {
  color: #5755d9;
  padding: 20px 10px 0;
  text-decoration: none;
  display: inline-block;
}

.step .step-item a:before {
  content: "";
  height: .6rem;
  width: .6rem;
  z-index: 1;
  background: #5755d9;
  border: .1rem solid #fff;
  border-radius: 50%;
  display: block;
  position: absolute;
  top: .2rem;
  left: 50%;
  transform: translateX(-50%);
}

.step .step-item.active a:before {
  background: #fff;
  border: .1rem solid #5755d9;
}

.step .step-item.active ~ .step-item:before {
  background: #dadee4;
}

.step .step-item.active ~ .step-item a {
  color: #bcc3ce;
}

.step .step-item.active ~ .step-item a:before {
  background: #dadee4;
}

.tab {
  border-bottom: .05rem solid #dadee4;
  flex-wrap: wrap;
  -ms-flex-align: center;
  align-items: center;
  margin: .2rem 0 .15rem;
  list-style: none;
  display: flex;
}

.tab .tab-item {
  margin-top: 0;
}

.tab .tab-item a {
  color: inherit;
  border-bottom: .1rem solid #0000;
  margin: 0 .4rem 0 0;
  padding: .4rem .2rem .3rem;
  text-decoration: none;
  display: block;
}

.tab .tab-item a:focus, .tab .tab-item a:hover {
  color: #5755d9;
}

.tab .tab-item a.active, .tab .tab-item.active a {
  color: #5755d9;
  border-bottom-color: #5755d9;
}

.tab .tab-item.tab-action {
  text-align: right;
  flex: 1 0 auto;
}

.tab .tab-item .btn-clear {
  margin-top: -.2rem;
}

.tab.tab-block .tab-item {
  text-align: center;
  flex: 1 0 0;
}

.tab.tab-block .tab-item a {
  margin: 0;
}

.tab.tab-block .tab-item .badge[data-badge]:after {
  position: absolute;
  top: .1rem;
  right: .1rem;
  transform: translate(0);
}

.tab:not(.tab-block) .badge {
  padding-right: 0;
}

.tile {
  -ms-flex-align: start;
  -ms-flex-line-pack: justify;
  align-content: space-between;
  align-items: flex-start;
  display: flex;
}

.tile .tile-action, .tile .tile-icon {
  flex: none;
}

.tile .tile-content {
  flex: auto;
}

.tile .tile-content:not(:first-child) {
  padding-left: .4rem;
}

.tile .tile-content:not(:last-child) {
  padding-right: .4rem;
}

.tile .tile-subtitle, .tile .tile-title {
  line-height: 1.2rem;
}

.tile.tile-centered {
  -ms-flex-align: center;
  align-items: center;
}

.tile.tile-centered .tile-content {
  overflow: hidden;
}

.tile.tile-centered .tile-subtitle, .tile.tile-centered .tile-title {
  text-overflow: ellipsis;
  white-space: nowrap;
  margin-bottom: 0;
  overflow: hidden;
}

.toast {
  color: #fff;
  width: 100%;
  background: #303742f2;
  border: .05rem solid #303742;
  border-radius: .1rem;
  padding: .4rem;
  display: block;
}

.toast.toast-primary {
  background: #5755d9f2;
  border-color: #5755d9;
}

.toast.toast-success {
  background: #32b643f2;
  border-color: #32b643;
}

.toast.toast-warning {
  background: #ffb700f2;
  border-color: #ffb700;
}

.toast.toast-error {
  background: #e85600f2;
  border-color: #e85600;
}

.toast a {
  color: #fff;
  text-decoration: underline;
}

.toast a.active, .toast a:active, .toast a:focus, .toast a:hover {
  opacity: .75;
}

.toast .btn-clear {
  margin: .1rem;
}

.toast p:last-child {
  margin-bottom: 0;
}

.tooltip {
  position: relative;
}

.tooltip:after {
  color: #fff;
  content: attr(data-tooltip);
  max-width: 320px;
  opacity: 0;
  pointer-events: none;
  text-overflow: ellipsis;
  white-space: pre;
  z-index: 300;
  background: #303742f2;
  border-radius: .1rem;
  padding: .2rem .4rem;
  font-size: .7rem;
  transition: opacity .2s, transform .2s;
  display: block;
  position: absolute;
  bottom: 100%;
  left: 50%;
  overflow: hidden;
  transform: translate(-50%, .4rem);
}

.tooltip:focus:after, .tooltip:hover:after {
  opacity: 1;
  transform: translate(-50%, -.2rem);
}

.tooltip.disabled, .tooltip[disabled] {
  pointer-events: auto;
}

.tooltip.tooltip-right:after {
  bottom: 50%;
  left: 100%;
  transform: translate(-.2rem, 50%);
}

.tooltip.tooltip-right:focus:after, .tooltip.tooltip-right:hover:after {
  transform: translate(.2rem, 50%);
}

.tooltip.tooltip-bottom:after {
  top: 100%;
  bottom: auto;
  transform: translate(-50%, -.4rem);
}

.tooltip.tooltip-bottom:focus:after, .tooltip.tooltip-bottom:hover:after {
  transform: translate(-50%, .2rem);
}

.tooltip.tooltip-left:after {
  bottom: 50%;
  left: auto;
  right: 100%;
  transform: translate(.4rem, 50%);
}

.tooltip.tooltip-left:focus:after, .tooltip.tooltip-left:hover:after {
  transform: translate(-.2rem, 50%);
}

@keyframes loading {
  0% {
    transform: rotate(0);
  }

  100% {
    transform: rotate(360deg);
  }
}

@keyframes slide-down {
  0% {
    opacity: 0;
    transform: translateY(-1.6rem);
  }

  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

.text-primary {
  color: #5755d9 !important;
}

a.text-primary:focus, a.text-primary:hover {
  color: #4240d4;
}

a.text-primary:visited {
  color: #6c6ade;
}

.text-secondary {
  color: #e5e5f9 !important;
}

a.text-secondary:focus, a.text-secondary:hover {
  color: #d1d0f4;
}

a.text-secondary:visited {
  color: #fafafe;
}

.text-gray {
  color: #bcc3ce !important;
}

a.text-gray:focus, a.text-gray:hover {
  color: #adb6c4;
}

a.text-gray:visited {
  color: #cbd0d9;
}

.text-light {
  color: #fff !important;
}

a.text-light:focus, a.text-light:hover {
  color: #f2f2f2;
}

a.text-light:visited {
  color: #fff;
}

.text-dark {
  color: #3b4351 !important;
}

a.text-dark:focus, a.text-dark:hover {
  color: #303742;
}

a.text-dark:visited {
  color: #455060;
}

.text-success {
  color: #32b643 !important;
}

a.text-success:focus, a.text-success:hover {
  color: #2da23c;
}

a.text-success:visited {
  color: #39c94b;
}

.text-warning {
  color: #ffb700 !important;
}

a.text-warning:focus, a.text-warning:hover {
  color: #e6a500;
}

a.text-warning:visited {
  color: #ffbe1a;
}

.text-error {
  color: #e85600 !important;
}

a.text-error:focus, a.text-error:hover {
  color: #cf4d00;
}

a.text-error:visited {
  color: #ff6003;
}

.bg-primary {
  color: #fff;
  background: #5755d9 !important;
}

.bg-secondary {
  background: #f1f1fc !important;
}

.bg-dark {
  color: #fff;
  background: #303742 !important;
}

.bg-gray {
  background: #f7f8f9 !important;
}

.bg-success {
  color: #fff;
  background: #32b643 !important;
}

.bg-warning {
  color: #fff;
  background: #ffb700 !important;
}

.bg-error {
  color: #fff;
  background: #e85600 !important;
}

.c-hand {
  cursor: pointer;
}

.c-move {
  cursor: move;
}

.c-zoom-in {
  cursor: zoom-in;
}

.c-zoom-out {
  cursor: zoom-out;
}

.c-not-allowed {
  cursor: not-allowed;
}

.c-auto {
  cursor: auto;
}

.d-block {
  display: block;
}

.d-inline {
  display: inline;
}

.d-inline-block {
  display: inline-block;
}

.d-flex {
  display: flex;
}

.d-inline-flex {
  display: inline-flex;
}

.d-hide, .d-none {
  display: none !important;
}

.d-visible {
  visibility: visible;
}

.d-invisible {
  visibility: hidden;
}

.text-hide {
  color: #0000;
  text-shadow: none;
  background: none;
  border: 0;
  font-size: 0;
  line-height: 0;
}

.text-assistive {
  clip: rect(0, 0, 0, 0);
  height: 1px;
  width: 1px;
  border: 0;
  margin: -1px;
  padding: 0;
  position: absolute;
  overflow: hidden;
}

.divider, .divider-vert {
  display: block;
  position: relative;
}

.divider-vert[data-content]:after, .divider[data-content]:after {
  color: #bcc3ce;
  content: attr(data-content);
  background: #fff;
  padding: 0 .4rem;
  font-size: .7rem;
  display: inline-block;
  transform: translateY(-.65rem);
}

.divider {
  height: .05rem;
  border-top: .05rem solid #f1f3f5;
  margin: .4rem 0;
}

.divider[data-content] {
  margin: .8rem 0;
}

.divider-vert {
  padding: .8rem;
  display: block;
}

.divider-vert:before {
  content: "";
  border-left: .05rem solid #dadee4;
  display: block;
  position: absolute;
  top: .4rem;
  bottom: .4rem;
  left: 50%;
  transform: translateX(-50%);
}

.divider-vert[data-content]:after {
  padding: .2rem 0;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.loading {
  min-height: .8rem;
  pointer-events: none;
  position: relative;
  color: #0000 !important;
}

.loading:after {
  content: "";
  height: .8rem;
  opacity: 1;
  width: .8rem;
  z-index: 1;
  background: none;
  border: .1rem solid #0000;
  border-color: #0000 #0000 #5755d9 #5755d9;
  border-radius: 50%;
  margin-top: -.4rem;
  margin-left: -.4rem;
  padding: 0;
  animation: .5s linear infinite loading;
  display: block;
  position: absolute;
  top: 50%;
  left: 50%;
}

.loading.loading-lg {
  min-height: 2rem;
}

.loading.loading-lg:after {
  height: 1.6rem;
  width: 1.6rem;
  margin-top: -.8rem;
  margin-left: -.8rem;
}

.clearfix:after {
  clear: both;
  content: "";
  display: table;
}

.float-left {
  float: left !important;
}

.float-right {
  float: right !important;
}

.p-relative {
  position: relative !important;
}

.p-absolute {
  position: absolute !important;
}

.p-fixed {
  position: fixed !important;
}

.p-sticky {
  position: sticky !important;
}

.p-centered {
  float: none;
  margin-left: auto;
  margin-right: auto;
  display: block;
}

.flex-centered {
  -ms-flex-align: center;
  justify-content: center;
  align-items: center;
  display: flex;
}

.m-0 {
  margin: 0 !important;
}

.mb-0 {
  margin-bottom: 0 !important;
}

.ml-0 {
  margin-left: 0 !important;
}

.mr-0 {
  margin-right: 0 !important;
}

.mt-0 {
  margin-top: 0 !important;
}

.mx-0 {
  margin-left: 0 !important;
  margin-right: 0 !important;
}

.my-0 {
  margin-top: 0 !important;
  margin-bottom: 0 !important;
}

.m-1 {
  margin: .2rem !important;
}

.mb-1 {
  margin-bottom: .2rem !important;
}

.ml-1 {
  margin-left: .2rem !important;
}

.mr-1 {
  margin-right: .2rem !important;
}

.mt-1 {
  margin-top: .2rem !important;
}

.mx-1 {
  margin-left: .2rem !important;
  margin-right: .2rem !important;
}

.my-1 {
  margin-top: .2rem !important;
  margin-bottom: .2rem !important;
}

.m-2 {
  margin: .4rem !important;
}

.mb-2 {
  margin-bottom: .4rem !important;
}

.ml-2 {
  margin-left: .4rem !important;
}

.mr-2 {
  margin-right: .4rem !important;
}

.mt-2 {
  margin-top: .4rem !important;
}

.mx-2 {
  margin-left: .4rem !important;
  margin-right: .4rem !important;
}

.my-2 {
  margin-top: .4rem !important;
  margin-bottom: .4rem !important;
}

.p-0 {
  padding: 0 !important;
}

.pb-0 {
  padding-bottom: 0 !important;
}

.pl-0 {
  padding-left: 0 !important;
}

.pr-0 {
  padding-right: 0 !important;
}

.pt-0 {
  padding-top: 0 !important;
}

.px-0 {
  padding-left: 0 !important;
  padding-right: 0 !important;
}

.py-0 {
  padding-top: 0 !important;
  padding-bottom: 0 !important;
}

.p-1 {
  padding: .2rem !important;
}

.pb-1 {
  padding-bottom: .2rem !important;
}

.pl-1 {
  padding-left: .2rem !important;
}

.pr-1 {
  padding-right: .2rem !important;
}

.pt-1 {
  padding-top: .2rem !important;
}

.px-1 {
  padding-left: .2rem !important;
  padding-right: .2rem !important;
}

.py-1 {
  padding-top: .2rem !important;
  padding-bottom: .2rem !important;
}

.p-2 {
  padding: .4rem !important;
}

.pb-2 {
  padding-bottom: .4rem !important;
}

.pl-2 {
  padding-left: .4rem !important;
}

.pr-2 {
  padding-right: .4rem !important;
}

.pt-2 {
  padding-top: .4rem !important;
}

.px-2 {
  padding-left: .4rem !important;
  padding-right: .4rem !important;
}

.py-2 {
  padding-top: .4rem !important;
  padding-bottom: .4rem !important;
}

.s-rounded {
  border-radius: .1rem;
}

.s-circle {
  border-radius: 50%;
}

.text-left {
  text-align: left;
}

.text-right {
  text-align: right;
}

.text-center {
  text-align: center;
}

.text-justify {
  text-align: justify;
}

.text-lowercase {
  text-transform: lowercase;
}

.text-uppercase {
  text-transform: uppercase;
}

.text-capitalize {
  text-transform: capitalize;
}

.text-normal {
  font-weight: 400;
}

.text-bold {
  font-weight: 700;
}

.text-italic {
  font-style: italic;
}

.text-large {
  font-size: 1.2em;
}

.text-small {
  font-size: .9em;
}

.text-tiny {
  font-size: .8em;
}

.text-muted {
  opacity: .8;
}

.text-ellipsis {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

.text-clip {
  text-overflow: clip;
  white-space: nowrap;
  overflow: hidden;
}

.text-break {
  -webkit-hyphens: auto;
  hyphens: auto;
  word-break: break-word;
  word-wrap: break-word;
}

/*# sourceMappingURL=index.00b1d8ff.css.map */
